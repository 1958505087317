import React from 'react';
import styled from '@emotion/styled';
import PrimaryBtnLink from '../components/common/PrimaryBtnLink';
import handshake from '../images/hand-shake.png';
import { MOBILE } from '../constants/responsive';

const PageContainer = styled('div')`
    margin: 0px auto;
    min-height: 100vh; 
    width:100%;
    background:var(--main-color-white);
    display:grid;
    place-content: center;
 `;
const ContentContainer = styled('div')`
    
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    text-align:center;
    row-gap: 1.6rem;
    @media (max-width:${MOBILE}) {
        
        row-gap: 0.8rem;
        }
`;
const Img = styled('img')`
     max-width: 90%;
`;

function SubmitConsultation() {
  return (
    <PageContainer>
      <ContentContainer>
        <h2>
          .Thank you.
        </h2>
        <h3>Our manager will contact you shortly</h3>
        <Img src={handshake} alt="hand shake" />
        <PrimaryBtnLink btnLabel="Back to main page" href="/" />
      </ContentContainer>
    </PageContainer>
  );
}

export default SubmitConsultation;

export const Head = () => (
  <>
    <title>Digital Marketing Agency Targetiva | Thank you for contacting us</title>
    <meta name="description" content="Digital Marketing Agency Targetiva" />
  </>
);
