import React from 'react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';

const A = styled('a')`
    color: var(--main-color-white);
    background: var(--main-color-black);
    border-color:  var(--main-color-black);
    border-radius: 0.8rem;
    &:hover {
        background: var(--main-color-white);
        color:var(--main-color-black)
    }
`;
function PrimaryBtnLink({
  btnLabel,
  href,
  onTarget,
  className,
  inner
}) {
  return (
    onTarget ? (
      <A className={cx('btn-primary', className)} href={href} target="_blank">
        {btnLabel}
      </A>
    )
      : <A className={cx('btn-primary', className)} href={href} target="_self">{btnLabel}</A>
  );
}

export default PrimaryBtnLink;
